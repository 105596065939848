const { url } = require('./config');

const schedules_api = {
    // Fetch data...
    async getSchedules () {
        const response = await fetch(url + '/schedules/all');
        const json = await response.json();
        return json;
    },
}

export { schedules_api };