import React from 'react';
import LoginForm from "../components/form/LoginForm";
import {Helmet} from "react-helmet";

// Component (here it's a view)
function Login() {
    // Updatable variable

    // DOM
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>ASFlavigny - Connexion</title>

                <meta name="title" content="ASFlavigny - Accueil" />
                <meta name="description" content="Le badminton club de Flavigny (ASF) vous propose toute son actualité à un seul endroit." />
                <meta name="robots" content="noindex, noimageindex" />

                <link rel="canonical" href="/login" />

                <script async src='https://www.googletagmanager.com/gtag/js?id=G-GLK66L6C6D'></script>
                <script type="text/javascript">
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'G-GLK66L6C6D');
                    `}
                </script>
            </Helmet>

            <div className="max-w-sm mx-auto my-0">
                <div className="my-5">
                    <LoginForm></LoginForm>
                </div>
            </div>
        </div>
    );
}

export default Login;
