import React, {useEffect, useState} from 'react';

import SlidesHeader from './SlidesHeader';

// Component (here it's a view)
function GlobalHeader(props) {

    const title = props.title;
    const description = props.description;
    const img = props.img;

    const slides = [
        {
            content: (
                <>
                    <div className={"hidden sm:block h-full"}>
                        <div className={"mx-9"}>
                            <div className={"max-w-lg my-0 mx-auto relative flex h-48 sm:h-96 mt-10"}>
                                <div className={"z-0 absolute right-0 top-0 w-full h-36 sm:h-[500px] bg-no-repeat bg-center bg-cover"} style={{backgroundImage: `url('${img}')`}}></div>
                                <div className={"z-10 animate-slide-in"}>
                                    <h1 className={"pl-10 pt-10 sm:pt-32 text-5xl sm:text-7xl text-white font-bold font-outfit"}>
                                        {title}
                                    </h1>
                                    <h2 className={"pl-10 text-2xl sm:text-4xl text-white font-outfit font-extralight"}>
                                        {description}
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"block sm:hidden"}>
                        <div className={"mx-9"}>
                            <h1 className={"pl-10 pt-3 sm:pt-32 text-5xl sm:text-7xl text-black font-bold font-outfit"}>
                                {title}
                            </h1>
                            <h2 className={"pl-10 text-2xl sm:text-4xl text-black font-outfit font-extralight"}>
                                {description}
                            </h2>
                        </div>
                        <div className={"w-full h-[500px] bg-no-repeat bg-center bg-cover"} style={{backgroundImage: `url('${img}')`}}></div>
                    </div>
                </>
            )
        },
    ];

    return (
        <div className={"relative flex h-80 sm:h-96 overflow-hidden w-full"} >
            <div className={"absolute w-full h-full"}>
                <div className={"hidden md:block h-full"}>
                    <div className={"mx-9"}>
                        <div className={"max-w-lg my-0 mx-auto relative flex h-48 sm:h-96 mt-10 w-full overflow-hidden"}>
                            <div className={"z-0 absolute right-0 top-0 w-full h-36 sm:h-[500px] bg-no-repeat bg-center bg-cover"} style={{backgroundImage: `url('${img}')`}}></div>
                            <img className={"z-10 animate-slide-in-full"} height={500} src="https://asfbad54.fr/content/photo/1/vaguesite.png" alt={title}/>
                            <div className={"z-10 absolute animate-slide-in"}>
                                <h1 className={"pl-10 pt-10 sm:pt-32 text-5xl sm:text-7xl text-white font-bold font-outfit"}>
                                    {title}
                                </h1>
                                <h2 className={"pl-10 text-2xl sm:text-4xl text-white font-outfit font-extralight"}>
                                    {description}
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"block md:hidden space-y-5"}>
                    <div className={"mx-9"}>
                        <h1 className={"pt-10 text-5xl sm:text-7xl text-black font-bold font-outfit"}>
                            {title}
                        </h1>
                        <h2 className={"text-2xl sm:text-4xl text-black font-outfit font-extralight"}>
                            {description}
                        </h2>
                    </div>
                    <div className={"w-full h-52 bg-no-repeat bg-center bg-cover"} style={{backgroundImage: `url('${img}')`}}></div>
                </div>
            </div>
        </div>
    )
}

export default GlobalHeader;
