import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import {news_api} from "../../api/news_api";

// Component (here it's a view)
const Mobile = forwardRef((props, ref) => {
    const [open, setOpen] = useState(false);

    useImperativeHandle(ref, () => ({

        seePopup() {
            setOpen(!open);
        }

    }));

    const navItems = props.items.map((item) =>
        <li key={item.title}>
            <a href={item.url}>
                <div className={"text-2xl font-outfit font-bold"}>
                    {item.title}
                </div>
            </a>
        </li>
    );

    return (
        <div>
            {open &&
                <div>
                    <div className="fixed top-0 left-0 right-0 bottom-0 z-50 overflow-auto bg-flavigny text-white">
                        <div className="w-full h-full flex flex-col items-center justify-center">
                            <div className="absolute top-0 right-0 mr-1 cursor-pointer py-8 px-7" onClick={() => setOpen(false)}>
                                <div className={"bg-white rounded-full h-1 w-8 rotate-45"}></div>
                                <div className={"bg-white rounded-full h-1 w-8 -rotate-45 -mt-1"}></div>
                            </div>
                            <ul className={"text-center space-y-20"}>
                                {navItems}
                            </ul>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
});

export default Mobile;