const { url } = require('./config');

const album_api = {

    // Fetch data...
    async getAlbums () {
        const response = await fetch(url + '/albums/all');
        const json = await response.json();
        return json;
    },

    async getAlbumsWithLimit (limit) {
        const response = await fetch(url + '/albums/all/' + limit);
        const json = await response.json();
        return json;
    },

    async getAlbumsWithLimitAndOffset (limit, offset) {
        const response = await fetch(url + '/albums/all/' + limit + '/' + offset);
        const json = await response.json();
        return json;
    },

    async getAlbum (albumId) {
        const response = await fetch(url + '/albums/album/' + albumId);
        const json = await response.json();
        return json;
    },

    async getAlbumByName(albumName) {
        const response = await fetch(url + '/albums/name/' + albumName);
        const json = await response.json();
        return json;
    },

    async insertAlbum(name, urlAlbum) {
        const response = await fetch(url + '/albums',
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization':"Basic " + window.btoa(localStorage.username + ':' + localStorage.password)
                },
                method: "POST",
                body: JSON.stringify({
                    name: name,
                    url: urlAlbum
                })
            });
        return response.status;
    },

    async deleteAlbum(id) {
        const response = await fetch(url + '/albums/' + id,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization':"Basic " + window.btoa(localStorage.username + ':' + localStorage.password)
                },
                method: "DELETE"
            });
        return response.status;
    },

    async updateAlbum(id, name, urlAlbum) {
        const response = await fetch(url + '/albums/' + id,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization':"Basic " + window.btoa(localStorage.username + ':' + localStorage.password)
                },
                method: "PUT",
                body: JSON.stringify({
                    name: name,
                    url: urlAlbum
                })
            });
        return response.status;
    }

}
export { album_api };