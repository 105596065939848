import React, {useEffect, useState} from 'react';

// Component (here it's a view)
function Footer() {

    return (
        <div className="bottom-0 w-full">
            <div className="border-b border-black"></div>
            <div className="my-5 mx-auto w-16 h-12">
                <a href="/login"><img src="/img/logo-black.png" alt="Logo noir du club de badminton de l'ASFlavigny"/></a>
            </div>
        </div>
    )
}

export default Footer;