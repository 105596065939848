const { url } = require('./config');

const announcements_api = {

    async getAnnouncements () {
        const response = await fetch(url + '/announcements');
        const json = await response.json();
        return json;
    },

    async updateAnnouncement(id, content, link, link_text) {
        const response = await fetch(url + '/announcements',
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization':"Basic " + window.btoa(localStorage.username + ':' + localStorage.password)
                },
                method: "PUT",
                body: JSON.stringify({
                    id: id,
                    content: content,
                    link: link,
                    link_text: link_text
                })
            });
        return response.status;
    }
}

export { announcements_api };