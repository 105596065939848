const { url } = require('./config');

const photo_api = {

    // Fetch data...
    async getPhotosByAlbum (album_name) {
        const resp = await fetch(url + '/photos/all/' + album_name);
        const json = await resp.json();
        return json;
    },

    async getPhotosByAlbumWithLimitAndOffset (album_name, limit, offset) {
        const resp = await fetch(url + '/photos/all/' + album_name + '/' + limit + '/' + offset);
        const json = await resp.json();
        return json;
    },

    async getPhoto (photoId) {
        const response = await fetch(url + '/photos/photo/' + photoId);
        const json = await response.json();
        return json;
    },

    async getLastFourPhotosByAlbum (albumId) {
        const response = await fetch(url + '/photos/album/' + albumId);
        const json = await response.json();
        return json;
    },

    async insertPhoto(urlPhoto, img, minia, album_id) {
        const response = await fetch(url + '/photos',
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization':"Basic " + window.btoa(localStorage.username + ':' + localStorage.password)
                },
                method: "POST",
                body: JSON.stringify({
                    url: urlPhoto,
                    img: img,
                    minia: minia,
                    album_id: album_id
                })
            });
        return response.status;
    },

    async deletePhoto(id) {
        const response = await fetch(url + '/photos/' + id,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization':"Basic " + window.btoa(localStorage.username + ':' + localStorage.password)
                },
                method: "DELETE"
            });
        return response.status;
    }
}
export { photo_api };