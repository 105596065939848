import React, {useEffect, useState, useCallback} from "react";
import EventProp from "../../components/events/EventProp";
import {event_api} from "../../api/event_api";

function Events(props) {

    const sha256 = useCallback(async (text) => {
        const encoder = new TextEncoder();
        const data = encoder.encode(text);
        return crypto.subtle.digest("SHA-256", data).then(bufferToHex);
    }, []);

    const textToColor = useCallback(async (text) => {
        // Calcule le hash SHA-256 de la chaîne de texte
        const hash = await sha256(text);

        // Extrait les 6 premiers caractères du hash
        const shortHash = hash.substring(0, 6);

        // Convertit le hash en une couleur hexadécimale
        const color = `#${shortHash}`;

        return color;
    }, [sha256]);

    const [limit] = useState(props.limit || 10); // Number of news to fetch [default: 5

    // Fonction utilitaire pour convertir un tableau d'octets en une chaîne hexadécimale
    function bufferToHex(buffer) {
        const view = new DataView(buffer);
        let hex = '';
        for (let i = 0; i < view.byteLength; i += 4) {
            const value = view.getUint32(i);
            hex += value.toString(16).padStart(8, '0');
        }
        return hex;
    }

    const [allevents, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(-1);
    const months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Décembre"];

    // Create dictionary of events by date
    const eventsByMonths = {};
    allevents.forEach((event) => {
        const date = months[new Date(event.date).getMonth()] + " " + new Date(event.date).getFullYear();
        if (eventsByMonths[date] == null) eventsByMonths[date] = [];
        eventsByMonths[date].push(event);
    });

    const fetchData = useCallback(async (offset = 0) => {
        const resp = await event_api.getEventsFromDateWithLimitAndOffset(new Date().toDateString(), limit, offset);
        for (let i = 0; i < resp.length; i++) {
            resp[i].color = await textToColor(resp[i].type);
        }
        setEvents((prevEvents) => [...prevEvents, ...resp]);
    }, [limit, textToColor]);

    // Function called on load of the component...
    useEffect(() => {
        // Example of fetch API data
        console.log("Fetching data...");
        fetchData();
    }, [fetchData]);

    const events = Object.entries(eventsByMonths).map(([date, events]) => {
        return (
            <div key={date}>
                <h3 className={"text-2xl mb-3 font-medium font-outfit text-green-600"}>{date}</h3>
                <ul className={"space-y-5"}>
                    {events.map((event) => {
                        return (
                            <li
                                key={event.id}
                                className={"bg-gray-50 p-3 border-l-4 cursor-pointer"}
                                style={{borderColor: event.color}}
                                onClick={() => setSelectedEvent(event.id)}
                            >
                                <EventProp event={event} selectedEvent={selectedEvent}/>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    });

    return (
        <div>
            { events.length > 0 && 
                <div className={"space-y-5"}>
                    {events}
                </div>
            }
            { events.length === 0 && <p className={"pt-2 text-center text-2xl font-medium font-outfit text-green-600"}>Aucun événement à venir</p> }
        </div>
    );
}

export default Events;