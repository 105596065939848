import React, {useEffect, useRef, useState} from 'react';
import {news_api} from "../api/news_api";
import NewsProp from "../components/news/NewsProp";
import PopUp from "../components/global/PopUp";
import NewsForm from "../components/form/NewsForm";
import {Helmet} from "react-helmet";

// Component (here it's a view)
function News() {
    // Updatable variable
    const [allnews, setNews] = useState([]);

    const [limit] = useState(5); // Number of news to fetch [default: 5
    const [offset, setOffset] = useState(0); // Number of news to skip [default: 0]
    const [hasMore, setHasMore] = useState(true); // Boolean to know if there is more news to fetch

    const popUpRef = useRef();

    const news = allnews.map((news) =>
        <NewsProp key={news.id} news = {news}/>
    );

    async function fetchData(offset = 0) {
        const resp = await news_api.getNewsWithLimitAndOffset(limit, offset);
        if (resp.length === 0 || resp.length % limit !== 0) {
            await setHasMore(false);
        }
        // set resp to existing news
        setNews([...allnews, ...resp]);
    };

    // Function called on load of the component...
    useEffect(() => {
        // Example of fetch API data
        fetchData();
    }, []);

    const showMore = () => {
        if (!hasMore) return;
        setOffset(offset + limit);
        fetchData(offset + limit);
    }

    window.onscroll = function (ev) {
        const bottom = Math.ceil(window.innerHeight + window.scrollY) === document.documentElement.scrollHeight;
        if (bottom) {
            showMore();
        }
    }

    // DOM
    return (
        <div className="mt-6 mb-10">
            <Helmet>
                <meta property="og:title" content="Actualités du club | ASFlavigny" />
                <meta property="og:site_name" content="Actualités du club | ASFlavigny" />
                <meta property="og:description" content="Retrouvez les actualités du club de badminton de Flavigny (ASF)." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://asfbad54.fr/actus" />
                <meta property="og:image" content="https://asfbad54.fr/img/logo-blue.png" />
        
                <meta charSet="utf-8" />
                <title>Actualités du club | ASFlavigny</title>
                <meta name="description" content="Retrouvez les actualités du club de badminton de Flavigny (ASF)." />
                <link rel="canonical" href="/actus"/>
                <script async src='https://www.googletagmanager.com/gtag/js?id=G-GLK66L6C6D'></script>
                <script type="text/javascript">
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'G-GLK66L6C6D');
                    `}
                </script>
            </Helmet>
            <PopUp ref={popUpRef} children={<NewsForm sended={() => {popUpRef.current.closePopup();}} />}/>
            {localStorage.getItem('username') !== null &&
                <div className="mb-10 md:flex">
                    <div onClick={() => popUpRef.current.seePopup()} className="relative bg-no-repeat bg-center bg-cover rounded h-40 w-full cursor-pointer" style={{backgroundImage: "url('/img/add.jpg')"}}>
                    </div>
                </div>
            }
            <div className='grid container mx-auto grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10'>
                {news}
            </div>
            <div>
                {hasMore &&
                    <div className="flex justify-center">
                        <button onClick={() => showMore()} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4">
                            Voir plus
                        </button>
                    </div>
                }
            </div>
        </div>
    );
}

export default News;
