import React, {useEffect, useRef, useState, useCallback} from "react";
import {Helmet} from "react-helmet";
import EventForm from "../components/form/EventForm";
import PopUp from "../components/global/PopUp";
import EventProp from "../components/events/EventProp";
import {event_api} from "../api/event_api";

function Events() {

    // async function textToColor(text) {
    //     // Calcule le hash SHA-256 de la chaîne de texte
    //     const hash = await sha256(text);

    //     // Extrait les 6 premiers caractères du hash
    //     const shortHash = hash.substring(0, 6);

    //     // Convertit le hash en une couleur hexadécimale
    //     const color = `#${shortHash}`;

    //     return color;
    // }

        // Fonction de hachage SHA-256
    // function sha256(text) {
    //     const encoder = new TextEncoder();
    //     const data = encoder.encode(text);
    //     return crypto.subtle.digest("SHA-256", data).then(bufferToHex);
    // }

    const sha256 = useCallback(async (text) => {
        const encoder = new TextEncoder();
        const data = encoder.encode(text);
        return crypto.subtle.digest("SHA-256", data).then(bufferToHex);
    }, []);

    const textToColor = useCallback(async (text) => {
        // Calcule le hash SHA-256 de la chaîne de texte
        const hash = await sha256(text);

        // Extrait les 6 premiers caractères du hash
        const shortHash = hash.substring(0, 6);

        // Convertit le hash en une couleur hexadécimale
        const color = `#${shortHash}`;

        return color;
    }, [sha256]);

    const [limit] = useState(10); // Number of news to fetch [default: 5
    const [offset, setOffset] = useState(0); // Number of news to skip [default: 0]
    const [hasMore, setHasMore] = useState(true); // Boolean to know if there is more news to fetch

    // Fonction utilitaire pour convertir un tableau d'octets en une chaîne hexadécimale
    function bufferToHex(buffer) {
        const view = new DataView(buffer);
        let hex = '';
        for (let i = 0; i < view.byteLength; i += 4) {
            const value = view.getUint32(i);
            hex += value.toString(16).padStart(8, '0');
        }
        return hex;
    }

    const [allevents, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(-1);
    const months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Décembre"];

    // Create dictionary of events by date
    const eventsByMonths = {};
    allevents.forEach((event) => {
        const date = months[new Date(event.date).getMonth()] + " " + new Date(event.date).getFullYear();
        if (eventsByMonths[date] == null) eventsByMonths[date] = [];
        eventsByMonths[date].push(event);
    });

    const fetchData = useCallback(async (offset = 0) => {
        const resp = await event_api.getEventsFromDateWithLimitAndOffset(new Date().toDateString(), limit, offset);
        if (resp.length === 0 || resp.length % limit !== 0) {
            setHasMore(false);
        }
        for (let i = 0; i < resp.length; i++) {
            resp[i].color = await textToColor(resp[i].type);
        }
        setEvents((prevEvents) => [...prevEvents, ...resp]);
    }, [limit, textToColor]);

    async function fetchEventsFromDateWithSearch(date, search) {
        setHasMore(false);
        const resp = await event_api.getEventsFromDateWithSearch(date.toDateString(), search);
        for (let i = 0; i < resp.length; i++) {
            resp[i].color = await textToColor(resp[i].type);
        }
        setEvents(resp);
    }

    // Function called on load of the component...
    useEffect(() => {
        // Example of fetch API data
        fetchData();
    }, [fetchData]);

    const showMore = () => {
        if (!hasMore) return;
        setOffset(offset + limit);
        fetchData(offset + limit);
    }

    window.onscroll = function (ev) {
        const bottom = Math.ceil(window.innerHeight + window.scrollY) === document.documentElement.scrollHeight;
        if (bottom) {
            showMore();
        }
    }

    const popUpRef = useRef();

    const events = Object.entries(eventsByMonths).map(([date, events]) => {
        return (
            <div key={date}>
                <h3 className={"text-2xl mb-3 font-medium font-outfit text-green-600"}>{date}</h3>
                <ul className={"space-y-5"}>
                    {events.map((event) => {
                        return (
                            <li
                                key={event.id}
                                className={"bg-gray-50 p-3 border-l-4 cursor-pointer"}
                                style={{borderColor: event.color}}
                                onClick={() => setSelectedEvent(event.id)}
                            >
                                <EventProp event={event} selectedEvent={selectedEvent}/>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    });

    return (
        <div className="mb-10">
            <Helmet>
                <meta property="og:title" content="Événements du club | ASFlavigny" />
                <meta property="og:site_name" content="Événements du club | ASFlavigny" />
                <meta property="og:description" content="Retrouvez les événements du club de badminton de Flavigny (ASF)." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://asfbad54.fr/evenements" />
                <meta property="og:image" content="https://asfbad54.fr/img/logo-blue.png" />
        
                <meta charSet="utf-8" />
                <title>Événements du club | ASFlavigny</title>
                <meta name="description" content="Retrouvez les événements du club de badminton de Flavigny (ASF)." />
                <link rel="canonical" href="/evenements"/>
                <script async src='https://www.googletagmanager.com/gtag/js?id=G-GLK66L6C6D'></script>
                <script type="text/javascript">
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'G-GLK66L6C6D');
                    `}
                </script>
            </Helmet>
            <PopUp ref={popUpRef} children={<EventForm sended={() => {popUpRef.current.closePopup();}} />}/>

            <div>
                {localStorage.getItem('username') !== null &&
                    <div className="mb-10 md:flex">
                        <div onClick={() => popUpRef.current.seePopup()} className="relative bg-no-repeat bg-center bg-cover rounded h-40 w-full cursor-pointer" style={{backgroundImage: "url('/img/add.jpg')"}}>
                        </div>
                    </div>
                }
            </div>

            <input type="text" placeholder="Rechercher un événement..." className="w-full mt-5 mb-8 px-3 py-2 border border-gray-300 rounded shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" onChange={(e) => fetchEventsFromDateWithSearch(new Date(), e.target.value)}/>

            { events.length > 0 && 
                <div className={"space-y-5"}>
                    {events}
                </div>
            }
            { events.length === 0 && <p className={"pt-2 text-center text-2xl font-medium font-outfit text-green-600"}>Aucun événement à venir</p> }

            <div>
                {hasMore &&
                    <div className="flex justify-center">
                        <button onClick={() => showMore()} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4">
                            Voir plus
                        </button>
                    </div>
                }
            </div>
        </div>
    );
}

export default Events;
