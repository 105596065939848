import {useInput} from "../../hooks/input-hook";
import React from 'react';
import {tournament_api} from "../../api/tournament_api";

function TournamentCreateForm() {

    const { value:name, bind:bindName } = useInput('');
    const { value:type, bind:bindType } = useInput('');
    const { value:date, bind:bindDate } = useInput('');
    const { value:time_start, bind:bindTimeStart } = useInput('');
    const { value:time_end, bind:bindTimeEnd } = useInput('');
    const { value:nb_courts, bind:bindNbCourts } = useInput(0);
    const { value:nb_players, bind:bindNbPlayers } = useInput(0);
    const { value:allowSex, bind:bindAllowSex } = useInput(true);
    const { value:allowLevel, bind:bindAllowLevel } = useInput(true);
    const { value:time_break_minute, bind:bindTimeBreakMinute } = useInput(0);
    const { value:time_match_minute, bind:bindTimeMatchMinute } = useInput(0);
    const { value:password, bind:bindPassword } = useInput('');

    const handleSubmit = async (evt) => {
        evt.preventDefault();

        const data = {
            name: name,
            date: date,
            type: type,
            time_start: time_start,
            time_end: time_end,
            nb_court: nb_courts,
            nb_player: nb_players,
            allowSex: allowSex,
            allowLevel: allowLevel,
            time_break_minute: time_break_minute,
            time_match_minute: time_match_minute,
            password: password,
        }

        try {
            let result = await tournament_api.createTournament(data);
            if (result === 204) {
                alert("Tournoi créé !");
            } else {
                alert("Erreur lors de la création du tournoi !");
            }
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <div className="w-full">
            <form className="space-y-3 mt-2 w-full" onSubmit={handleSubmit} >
                <div>
                    <label htmlFor="name" className="text-sm font-medium text-gray-700">Nom du tournoi</label>
                    <input type="text" name="name" id="name" placeholder={"Nom du tournoi"}
                           className="w-full rounded-lg border border-gray-200 p-4 pe-12 text-sm shadow-sm" {...bindName} />
                </div>

                <div>
                    <label htmlFor="name" className="text-sm font-medium text-gray-700">Type du tournoi</label>
                    <input type="text" name="name" id="name" placeholder={"Type du tournoi"}
                           className="w-full rounded-lg border border-gray-200 p-4 pe-12 text-sm shadow-sm" {...bindType} />
                </div>

                <div>
                    <label htmlFor="date" className="text-sm font-medium text-gray-700">Date du tournoi</label>
                    <input type="date" name="date" id="date" placeholder={"Date du tournoi"}
                            className="w-full rounded-lg border border-gray-200 p-4 pe-12 text-sm shadow-sm" {...bindDate} />
                </div>

                <div>
                    <label htmlFor="time_start" className="text-sm font-medium text-gray-700">Heure de début du tournoi</label>
                    <input type="time" name="time_start" id="time_start" placeholder={"Heure de début du tournoi"}
                            className="w-full rounded-lg border border-gray-200 p-4 pe-12 text-sm shadow-sm" {...bindTimeStart} />
                </div>

                <div>
                    <label htmlFor="time_end" className="text-sm font-medium text-gray-700">Heure de fin du tournoi</label>
                    <input type="time" name="time_end" id="time_end" placeholder={"Heure de fin du tournoi"}
                            className="w-full rounded-lg border border-gray-200 p-4 pe-12 text-sm shadow-sm" {...bindTimeEnd} />
                </div>

                <div>
                    <label htmlFor="nb_courts" className="text-sm font-medium text-gray-700">Nombre de terrains</label>
                    <input type="number" name="nb_courts" id="nb_courts" placeholder={"Nombre de terrains"}
                            className="w-full rounded-lg border border-gray-200 p-4 pe-12 text-sm shadow-sm" {...bindNbCourts} />
                </div>

                <div>
                    <label htmlFor="nb_players" className="text-sm font-medium text-gray-700">Nombre de joueurs</label>
                    <input type="number" name="nb_players" id="nb_players" placeholder={"Nombre de joueurs"}
                            className="w-full rounded-lg border border-gray-200 p-4 pe-12 text-sm shadow-sm" {...bindNbPlayers} />
                </div>

                <div>
                    <label htmlFor="allowSex" className="text-sm font-medium text-gray-700">Différencier Homme/Femme</label>
                    <label htmlFor="checkAllowSex" className="block relative w-14 h-8 cursor-pointer">
                        <input type="checkbox" id="checkAllowSex" className="peer sr-only" checked={allowSex} {...bindAllowSex} />
                        <span
                            className="absolute inset-0 rounded-md bg-gray-300 transition peer-checked:bg-blue-500"
                        ></span>
                        <span
                            className="absolute inset-y-0 start-0 m-1 h-6 w-6 rounded-full bg-gray-300 ring-[6px] ring-inset ring-white transition-all peer-checked:start-9 peer-checked:w-2 peer-checked:bg-white peer-checked:ring-transparent"
                        ></span>
                    </label>
                </div>

                <div>
                    <label htmlFor="allowLevel" className="text-sm font-medium text-gray-700">Différencier les niveaux</label>
                    <label htmlFor="checkAllowLevel" className="block relative w-14 h-8 cursor-pointer">
                    <input type="checkbox" id="checkAllowLevel" className="peer sr-only" checked={allowLevel} {...bindAllowLevel} />
                        <span
                            className="absolute inset-0 rounded-md bg-gray-300 transition peer-checked:bg-blue-500"
                        ></span>
                        <span
                            className="absolute inset-y-0 start-0 m-1 h-6 w-6 rounded-full bg-gray-300 ring-[6px] ring-inset ring-white transition-all peer-checked:start-9 peer-checked:w-2 peer-checked:bg-white peer-checked:ring-transparent"
                        ></span>
                    </label>
                </div>

                <div>
                    <label htmlFor="time_break_minute" className="text-sm font-medium text-gray-700">Temps de pause (minutes)</label>
                    <input type="number" name="time_break_minute" id="time_break_minute" placeholder={"Temps de pause (minutes)"}
                            className="w-full rounded-lg border border-gray-200 p-4 pe-12 text-sm shadow-sm" {...bindTimeBreakMinute} />
                </div>

                <div>
                    <label htmlFor="time_match_minute" className="text-sm font-medium text-gray-700">Temps de match (minutes)</label>
                    <input type="number" name="time_match_minute" id="time_match_minute" placeholder={"Temps de match (minutes)"}
                            className="w-full rounded-lg border border-gray-200 p-4 pe-12 text-sm shadow-sm" {...bindTimeMatchMinute} />
                </div>

                <div>
                    <label htmlFor="password" className="text-sm font-medium text-gray-700">Mot de passe</label>
                    <input type="text" name="password" id="password" placeholder={"Mot de passe"}
                            className="w-full rounded-lg border border-gray-200 p-4 pe-12 text-sm shadow-sm" {...bindPassword} />
                </div>

                <button
                    className="w-full inline-block rounded border border-blue-500 bg-blue-500 px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-blue-500 focus:outline-none focus:ring active:text-indigo-500"
                >
                    Créer le tournoi
                </button>
            </form>
        </div>
    );
}

export default TournamentCreateForm;