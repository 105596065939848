import React, {useEffect, useRef, useState} from 'react';
import {photo_api} from "../../api/photo_api";
import PopUp from "../global/PopUp";
import DeleteAlbumForm from "../form/DeleteAlbumForm";
import EditAlbumForm from "../form/EditAlbumForm";

function AlbumProp (props) {

    const popUpDel = useRef();
    const popUpEdit = useRef();

    const [allcovers, setCover] = useState([]);
    const covers = allcovers.map((cover) =>
        <div key={cover.id} className={"z-10 w-full" + (allcovers.length === 3 && allcovers.indexOf(cover) === 1 ? " row-span-2" : " h-full")}>
            <img src={"/content" + cover.minia}
                 alt={"Photo de l'album " + props.album.name}
                 className="w-full h-full object-cover" />
        </div>
    );

    // Function called on load of the component...
    useEffect(() => {
        // Example of fetch API data
        async function fetchData() {
            const resp = await photo_api.getLastFourPhotosByAlbum(props.album.id);
            setCover(resp);
        }
        fetchData();
    }, []);

    return(
        <div>
            {localStorage.getItem('username') !== null &&
                <div className={"z-10 absolute flex space-x-2 m-2"}>
                    <div className={"bg-red-500 px-1 text-white rounded-full font-extrabold cursor-pointer"}
                         onClick={() => popUpDel.current.seePopup()}>SUPPRIMER</div>
                    <div className={"bg-orange-500 px-1 text-white rounded-full font-extrabold cursor-pointer"}
                        onClick={() => popUpEdit.current.seePopup()}>MODIFIER</div>
                </div>
            }
            <PopUp ref={popUpDel} children={<DeleteAlbumForm id={props.album.id} sended={() => {popUpDel.current.closePopup();}} />}/>
            <PopUp ref={popUpEdit} children={<EditAlbumForm album={props.album} sended={() => {popUpEdit.current.closePopup();}} />}/>
            <a href={'albums/' + props.album.url}>
                <div className={"bg-flavigny inline-block relative w-full"}>
                    <div style={{marginTop: '100%'}}></div>
                    <div style={{top: '30%'}} className={"absolute left-0 right-0 bottom-0 text-center text-9xl text-white"}>
                        ?
                    </div>
                    { covers.length >= 3 &&
                        <div className={"absolute overflow-hidden z-0 top-0 left-0 right-0 bottom-0 grid grid-cols-2 grid-rows-2"}>
                            {covers}
                        </div>
                    }
                    { covers.length === 2 &&
                        <div className={"absolute overflow-hidden z-0 top-0 left-0 right-0 bottom-0 grid grid-cols-2"}>
                            {covers}
                        </div>
                    }
                    { covers.length === 1 &&
                        <div className={"absolute overflow-hidden z-0 top-0 left-0 right-0 bottom-0"}>
                            {covers}
                        </div>
                    }
                    { props.album.date !== null && new Date(props.album.date) > new Date() - 15*24*60*60*1000 &&
                        <div className={"absolute left-0 top-0 -rotate-12 -translate-x-1/4 -translate-y-1/3 whitespace-nowrap rounded-full bg-amber-300 px-2.5 py-0.5 text-sm text-amber-800"}>
                            Nouveau !
                        </div>
                    }
                </div>
                <div className="mb-10 mt-4 text-green-600">
                    <h2 className={"font-bold font-outfit text-center text-2xl"}>{props.album.name.toUpperCase()}</h2>
                </div>
            </a>
        </div>
    );
}

export default AlbumProp;