import React, {forwardRef, useImperativeHandle, useState} from 'react';

// Component (here it's a view)
const PopUp = forwardRef((props, ref) => {
    const [open, setOpen] = useState(false);

    useImperativeHandle(ref, () => ({

        seePopup() {
            setOpen(!open);
        },

        closePopup() {
            setOpen(false);
        }

    }));

    // {props.children}
    // onClick={() => setOpen(false)}
    return (
        <div>
            {open &&
                <div className="custom-model-main model-open">
                    <div className="custom-model-inner">
                        <div className="close-btn" onClick={() => setOpen(false)}>×</div>
                        <div className="custom-model-wrap overflow-auto max-h-screenEight">
                            {props.children}
                        </div>
                    </div>
                    <div className="bg-overlay" onClick={() => setOpen(false)}></div>
                </div>
            }
        </div>
    );
});

export default PopUp;