const { url } = require('./config');

const contact_api = {
    // Fetch data...
    async getMessages () {
        const response = await fetch(url + '/contact/all',
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization':"Basic " + window.btoa(localStorage.username + ':' + localStorage.password)
                }
            });
        const json = await response.json();
        return json;
    },

    async insertMessage(name, email, subject, message) {
        const response = await fetch(url + '/contact',
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: "POST",
                body: JSON.stringify({
                    name: name,
                    email: email,
                    subject: subject,
                    message: message
                })
            });
        return response.status;
    }
}

export { contact_api };