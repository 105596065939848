import React, {useEffect, useState} from 'react';
import {news_api} from "../api/news_api";
import {useParams} from "react-router-dom";
import {users_api} from "../api/users_api";
import {Helmet} from "react-helmet";
const { url } = require('../api/config');

function Post () {
    const [news, setNews] = React.useState([])
    const { post } = useParams()

    // Function called on load of the component...
    useEffect(() => {
        // Example of fetch API data
        async function fetchData() {
            const respNews = await news_api.getPost(post);
            setNews(respNews);
        }
        fetchData();
    }, []);

    // DOM
    return (
        <div className="max-w-sm my-0 mx-auto">
            <Helmet>
                <meta property="og:title" content={news.title + " | ASFlavigny"} />
                <meta property="og:site_name" content={news.title + " | ASFlavigny"} />
                <meta property="og:description" content="Le badminton club de Flavigny (ASF), situé au sud de Nancy (près de Ludres et Richardménil), vous accueille dans une ambiance conviviale." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://asfbad54.fr" />
                <meta property="og:image" content={"https://asfbad54.fr/content" + news.img} />
        
                <meta charSet="utf-8" />
                <title>{news.title + " | ASFlavigny"}</title>
                {news.title &&
                    <script async src='https://www.googletagmanager.com/gtag/js?id=G-GLK66L6C6D'></script>
                }
                {news.title &&
                    <script type="text/javascript">
                        {`
                            window.dataLayer = window.dataLayer || [];
                            function gtag(){dataLayer.push(arguments);}
                            gtag('js', new Date());
                            gtag('config', 'G-GLK66L6C6D');
                        `}
                    </script>
                }
            </Helmet>

            <div className="mb-3">
                <h1 className="text-3xl font-bold font-outfit">
                    {news.title ? news.title.toUpperCase() : ""}
                </h1>
                <p className="text-green-600 font-outfit">
                    {new Date(news.date).toLocaleDateString('fr-FR')}
                </p>
            </div>
            <div className="mb-3">
                <img src={"/content" + news.img} alt={"Bannière de l'article " + news.title}/>
            </div>
            <div className="text-justify whitespace-pre-line">
                <div className={"news-content"} dangerouslySetInnerHTML={{__html: news.content}}></div>
            </div>
        </div>
    );
}

export default Post;
