import React, {useState} from 'react';
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'
import {useInput} from "../../hooks/input-hook";
import {event_api} from "../../api/event_api";
import Info from "../global/Info";
import {useRef} from "react";

const modules = {
    toolbar: [
        //[{ 'font': [] }],
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike"],
        [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
        ],
        ["link", "image"],
    ],
};

const formats = [
    //'font',
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
];

// Component (here it's a view)
function EventForm(props) {
    // Updatable variable
    const { value:name, bind:bindName } = useInput(props.event.name);
    const [description, setDescription] = useState(props.event.description);
    const { value:type, bind:bindType } = useInput(props.event.type);
    const { value:date, bind:bindDate } = useInput(props.event.date);
    const { value:link, bind:bindLink } = useInput(props.event.link);

    const infoRef = useRef();

    const handleSubmit = async (evt) => {
        evt.preventDefault();
        try {
            let result = await event_api.updateEvent(props.event.id, name, type, date, description, link);
            if (result === 204) {
                infoRef.current.seeInfo("green", "Evenement modifié !");
                setTimeout(() => {
                    props.sended();
                }, 2000);
            } else {
                infoRef.current.seeInfo("red", "Erreur lors de l'envoi de l'événement !");
            }
        } catch (e) {
            console.log(e);
        }
    }
    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="text-2xl text-center my-5">Modifier un événement</div>
                <div className="w-full mb-4">
                    <label>
                        <span className="text-gray-700">Nom</span>
                        <input className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" placeholder="Un nom aguicheur..." {...bindName} />
                    </label>
                </div>
                <div className="w-full mb-4">
                    <label>
                        <span className="text-gray-700">Type</span>
                        <input className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" placeholder="Un type d'événement..." {...bindType} />
                    </label>
                </div>
                <div className="w-full mb-4">
                    <label>
                        <span className="text-gray-700">Description</span>
                        <ReactQuill
                            theme='snow'
                            value={description}
                            onChange={setDescription}
                            style={{ width: "500px" }}
                            modules={modules}
                            formats={formats}
                        />
                    </label>
                </div>
                <div className="w-full mb-4">
                    <label htmlFor="date" className="text-gray-700">Date de l'événement</label>
                    <input type="date" name="date" id="date" placeholder={"Date de l'événement"}
                           className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...bindDate} />
                </div>
                <div className="w-full mb-4">
                    <label>
                        <span className="text-gray-700">Lien</span>
                        <input className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" placeholder="Un lien pour l'événement..." {...bindLink} />
                    </label>
                </div>
                <div className="mb-5">
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                        Mettre à jour
                    </button>
                </div>
            </form>
            <div className={"mt-5"}>
                <Info ref={infoRef}/>
            </div>
        </div>
    );
}

export default EventForm;