import React, {useEffect, useState} from 'react';

// Component (here it's a view)
function ScheduleProp(props) {

    const weekday = ["dimanche","lundi","mardi","mercredi","jeudi","vendredi","samedi"];

    const d = new Date();
    let day = weekday[d.getDay()];

    return(
      <div className={props.schedule.weekday.toLowerCase() === day ? 'w-full h-40 sm:h-56 bg-gradient-to-r from-green-400 to-green-600 relative rounded overflow-hidden': 'w-full h-40 sm:h-56 bg-gradient-to-r from-flavigny to-blue-600 relative rounded overflow-hidden'}>
          <span className={"text-white/10 text-[318px] leading-[208px] -ml-5 font-extrabold font-outfit"}>
            {props.schedule.weekday.toUpperCase()}
          </span>
          <div className="text-white text-5xl sm:text-8xl md:text-9xl font-extrabold font-outfit -ml-1 sm:-ml-1.5 md:-ml-2 absolute top-0 -mt-2.5 sm:-mt-5 md:-mt-6">
            {props.schedule.weekday.toUpperCase()}
            <div className="ml-4 flex">
                <div className="bg-white h-4 w-4 mt-0.5 mr-1">
                </div>
                <p className="text-xl leading-none font-light">
                    {props.schedule.address}
                </p>
            </div>
            <div className="ml-3 text-white text-4xl sm:text-6xl font-medium">
                {props.schedule.time_start.substring(0, 5)} - {props.schedule.time_end.substring(0, 5)}
            </div>
            <div className="ml-3 text-white text-2xl font-medium">
                {props.schedule.text}
            </div>
        </div>
      </div>
    );
}

export default ScheduleProp;