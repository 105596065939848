import React, {forwardRef, useImperativeHandle, useState} from 'react';

// Component (here it's a view)
const PopUp = forwardRef((props, ref) => {
    const [open, setOpen] = useState(false);

    useImperativeHandle(ref, () => ({

        seePopup() {
            setOpen(!open);
        }

    }));

    // {props.children}
    // onClick={() => setOpen(false)}
    return (
        <div>
            {open &&
                <div>
                    <div className="z-0 fixed top-0 left-0 w-full h-full bg-black z-50 flex items-center justify-center" onClick={() => setOpen(false)}>
                        <div className="z-10 absolute top-0 right-3 text-4xl text-white" onClick={() => setOpen(false)}>×</div>
                        <div onClick={(e) => e.stopPropagation()}>
                            {props.children}
                        </div>
                    </div>
                </div>
            }
        </div>
    );
});

export default PopUp;