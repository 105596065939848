import React, {useEffect, useRef, useState} from 'react';
import {album_api} from "../../api/album_api";
import AlbumProp from "../albums/AlbumProp";

function Albums () {

    const [allalbums, setAlbums] = useState([]);
    const albums = allalbums.map((album) =>
        <AlbumProp key={album.id} album = {album}/>
    );

    const popUpRef = useRef();

    // Function called on load of the component...
    useEffect(() => {
        // Example of fetch API data
        async function fetchData() {
            const resp = await album_api.getAlbumsWithLimit(3);
            setAlbums(resp);
        }
        fetchData();
    }, []);

    return(
        <div className={"space-y-5"}>
            {albums}
        </div>
    );
}

export default Albums;