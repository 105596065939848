import React, {useEffect, useRef, useState} from 'react';

// Component (here it's a view)
function IdeaBox() {

    // DOM
    return (
        <a href={"https://forms.gle/Ds4z9Aar2JY4N1nCA"} target="_blank">
        
            <div className="idea-box-container h-36">
                <div className='relative flex justify-center'>
                    <div className="absolute w-32 h-10 bg-gray-300 idea-box-paper">
                        <p className="text-black text-center text-lg italic font-outfit leading-10">
                            Mon idée...
                        </p>
                    </div>
                </div>
                <div className="absolute w-full h-32 bg-green-300 idea-box-top relative">
                    {/* Center horizontaly and verticaly the div */}
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                        <div className="w-52 h-8 bg-gray-100">
                        </div>
                    </div>
                </div>
                <div className="w-full bg-green-500 p-5 idea-box-front">
                    <p className="text-white text-center text-2xl font-medium font-outfit leading-10">
                        Cliquez ici pour accéder à la boite à idées
                    </p>
                </div>
            </div>
        </a>
    );
}

export default IdeaBox;