import {useParams} from "react-router-dom";
import React, {useEffect, useState} from 'react';
import {tournament_api} from "../api/tournament_api";

function TournamentPaper () {

    const { tournament } = useParams();

    const [currentTournament, setTournament] = useState({});
    const [matches, setMatches] = useState([]);
    
    function checkTournamentStatus(date, time_start, time_end) {
        // if date is in the future return 1
        // if date is in the past return -1
        // else return 0

        let today = new Date();

        let tournament_date = new Date(date);
        tournament_date.setHours(time_start.split(":")[0], time_start.split(":")[1], time_start.split(":")[2], 0);

        if (tournament_date > today) {
            return 1;
        }
        else {
            // check if current time is between time_start and time_end (time string format: "10:00:00")
            let tournament_time_end = new Date(date);
            tournament_time_end.setHours(time_end.split(":")[0], time_end.split(":")[1], time_end.split(":")[2], 0);
            if (today >= tournament_date && today <= tournament_time_end) {
                return 0;
            }
            else {
                return -1;
            }
        }
    }

    useEffect(() => {
        async function fetchData() {
            const resp = await tournament_api.getTournamentById(tournament);
            const respM = await tournament_api.getMatchesByTournamentId(tournament);
            const respP = await tournament_api.getPlayersByTournamentId(tournament);
            

            // associate players to matches
            respM.forEach((match) => {
                respP.forEach((player) => {
                    if (match.player1_id === player.id) {
                        match.player1 = player.firstname.charAt(0).toUpperCase() + player.firstname.slice(1) + " " + player.lastname.charAt(0).toUpperCase() + player.lastname.slice(1);
                    }
                    if (match.player2_id === player.id) {
                        match.player2 = player.firstname.charAt(0).toUpperCase() + player.firstname.slice(1) + " " + player.lastname.charAt(0).toUpperCase() + player.lastname.slice(1);
                    }
                    if (match.player3_id === player.id) {
                        match.player3 = player.firstname.charAt(0).toUpperCase() + player.firstname.slice(1) + " " + player.lastname.charAt(0).toUpperCase() + player.lastname.slice(1);
                    }
                    if (match.player4_id === player.id) {
                        match.player4 = player.firstname.charAt(0).toUpperCase() + player.firstname.slice(1) + " " + player.lastname.charAt(0).toUpperCase() + player.lastname.slice(1);
                    }
                });
            });

            setMatches(respM);
            setTournament(resp);

            const status = checkTournamentStatus(resp.date, resp.time_start, resp.time_end);

            if (status === 0) {

                respM.forEach((respM) => {

                    const time_start = new Date(respM.date);
                    time_start.setHours(respM.time_start.split(":")[0], respM.time_start.split(":")[1], respM.time_start.split(":")[2], 0);

                    const time_effective = new Date(respM.date);
                    if (respM.effective_time) {
                        time_effective.setHours(respM.effective_time.split(":")[0], respM.effective_time.split(":")[1], respM.effective_time.split(":")[2], 0);
                    }
                });
            }
        }
        fetchData();
    }, []);

    return (
        // generate tournament paper to print
        // split a page in two to print two tournament papers

        // a paper tournament is composed of:
        // - a header with tournament name, date, time, location, and logo
        // - two columns with players
        // - three cases for scores
        <div className="space-y-16">
            {matches.map((match) => (
                <div>
                    {/* <!-- match paper --> */}
                    <div className="border border-black">
                        {/* <!-- header --> */}
                        <div className="border-b border-black border-dashed p-5">
                            <div className="flex justify-between">
                                {/* <!-- logo --> */}
                                <div className="flex flex-col justify-center">
                                    <img className="w-16" src="/img/logo-black.png" alt="logo"/>
                                </div>
                                {/* <!-- tournament name --> */}
                                <div className="flex flex-col justify-center">
                                    <div className="text-2xl font-bold">{currentTournament.name}</div>
                                </div>
                                {/* <!-- date and time --> */}
                                <div className="flex flex-col justify-center">
                                    <div className="text-xl font-bold">{new Date(currentTournament.date).toLocaleDateString('fr-FR')}</div>
                                    <div className="text-lg">{currentTournament.time_start.substring(0,5)} - {currentTournament.time_end.substring(0,5)}</div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- players --> */}
                        <div className="p-5">
                            <div className="flex justify-between">
                                <div className="flex flex-col justify-center">
                                    <div className="text-lg">{match.player1}</div>
                                    <div className="text-lg">{match.player3}</div>
                                </div>
                                <div className="flex flex-col justify-center">
                                <div className="text-xl text-center">
                                        Terrain n°<b> . . .</b>
                                    </div>
                                    <div className="text-xl text-center">
                                        Match n°<b>{match.custom_id + 1}</b> à <b>{match.time_start.substring(0,5)}</b>
                                    </div>
                                </div>
                                <div className="flex flex-col justify-center">
                                    <div className="text-lg">{match.player2}</div>
                                    <div className="text-lg">{match.player4}</div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- scores --> */}
                        <div className="p-5">
                            <div className="flex justify-between">
                                <div className="flex flex-col justify-center space-y-2">
                                    <div className="border-2 border-black w-36 h-16"></div>
                                    <div className="border-2 border-black w-36 h-16"></div>
                                    <div className="border-2 border-black w-36 h-16"></div>
                                </div>
                                <div className="flex flex-col justify-center space-y-12">
                                    <div className="">Set n°1</div>
                                    <div className="">Set n°2</div>
                                    <div className="">Set n°3</div>
                                </div>
                                <div className="flex flex-col justify-center space-y-2">
                                    <div className="border-2 border-black w-36 h-16"></div>
                                    <div className="border-2 border-black w-36 h-16"></div>
                                    <div className="border-2 border-black w-36 h-16"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default TournamentPaper;