import React, {useEffect, useRef, useState} from 'react';

function Message (props) {

    return (
        <div className={"border-black border p-3"}>
            <div>
                <span className="text-xl font-extrabold">
                    {props.message.name}
                </span>
                <span> - </span>
                <span className="text-green-600 font-outfit">
                    {new Date(props.message.date).toLocaleDateString('fr-FR')}
                </span>
            </div>
            <div>
                <span className={"font-bold"}>
                    Sujet :
                </span>
                <span>
                    {" " + props.message.subject}
                </span>
            </div>
            <p className="ml-4 mt-5 text-justify whitespace-pre-line">{props.message.message}</p>
            <div className="mt-5 text-blue-800">
                <a href={"mailto:benji.vignot@gmail.com?subject=RE%3A%20" + props.message.subject + "&body=" + "Votre texte..." + "%0D%0A%0D%0A---%0D%0A%0D%0A" + props.message.name + " a dit :%0D%0A>" + props.message.message.replaceAll('\n', '%0D%0A>%20') + "."}>
                    Répondre à {props.message.email}
                </a>
            </div>
        </div>
    );
}
export default Message;
