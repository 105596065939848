import React from 'react';
import { Player } from 'video-react';

function Video (props) {
    return(
        <div className={"w-semiScreen h-semiScreen"}>
            <Player
                poster={"/content" + props.video.img}
                src={"/content" + props.video.url}
            />
        </div>
    );
}

export default Video;