import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';

// Component (here it's a view)
const Info = forwardRef((props, ref) => {
    const [open, setOpen] = useState(false);
    const [bgColor, setBgColor] = useState("#fff");
    const [borderColor, setBorderColor] = useState("#000");
    const [text, setText] = useState("");

    useImperativeHandle(ref, () => ({

        seeInfo(color, message) {
            if (color === 'red') {
                setBgColor('#ff8989');
                setBorderColor('#ad3535');
            }
            if (color === 'green') {
                setBgColor('#adffad');
                setBorderColor('#2e9d2e');
            }
            if (color === 'blue') {
                setBgColor('#9494ff');
                setBorderColor('#3737b4');
            }
            setText(message);
            if (!open) {
                setOpen(!open);
                // wait 5 seconds
                setTimeout(() => {
                    setOpen(false);
                }, 2000);
            }
        }

    }));

    return (
        <div>
            {open &&
                <div style={{ backgroundColor: bgColor, color: borderColor, borderColor: borderColor }} className={"border py-1.5 px-4 rounded"}>
                    {text}
                </div>
            }
        </div>
    );
});

export default Info;