import React, {useRef, useEffect, useState} from 'react';
import Mobile from "../menu/Mobile";

// Component (here it's a view)
function Navbar(props) {

    const [navColor, setNavColor] = useState(props.color);
    const [textColor, setTextColor] = useState('white');
    const [buttonColor, setButtonColor] = useState('rgb(34 197 94)');

    const popUpRef = useRef();

    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);

        if (position > 250) {
            setNavColor('white');
            setTextColor('black');
            setButtonColor('#3f91fc');
            return;
        } else {
            setNavColor('#3f91fc');
            setTextColor('white');
            setButtonColor('rgb(34 197 94)');
            return;
        }
    };

    useEffect(() => {
        if (props.color !== undefined) {
            window.addEventListener('scroll', handleScroll, {passive: true});

            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }
    }, []);

    // Updatable variable
    const allItems = [
        {title: 'ACTUS', url: '/actus', highlight: false, little: true},
        {title: 'ALBUMS', url: '/albums', highlight: false, little: true},
        {title: 'CALENDRIER', url: '/evenements', highlight: false, little: false},
        {title: 'TOURNOIS', url: '/tournois', highlight: false, little: false},
        {title: 'S\'INSCRIRE', url: '/inscription', highlight: false, little: true},
        {title: 'CONTACT', url: '/contact', highlight: true, little: true},
    ];

    const navItems = allItems.map((item) =>
        <li key={item.title} className={item.little ? "float-left flex-1 grow text-center" : "float-left flex-1 grow text-center hidden lg:block"}>
            <a href={item.url}>
                <div style={item.highlight ? (props.color !== undefined ? {backgroundColor: buttonColor} : {backgroundColor: '#3f91fc'}) : {}} className={item.highlight ? "text-white ml-4 my-4 py-2 px-9 font-bold font-outfit" : "my-6 font-bold font-outfit"}>
                        {item.title}
                </div>
            </a>
        </li>
    );

    // Function called on load of the component...
    useEffect(() => {
        // Example of fetch API data
        async function fetchData() {
            //
        }
        fetchData();
    }, []);

    // DOM
    return (
        <div style={props.color !== undefined ? {backgroundColor: navColor, color: textColor} : {backgroundColor: 'white', color: 'black'}} className={"z-40 fixed w-full top-0 duration-500 transition-all"}>
            <div className="mx-9">
                <div className="relative max-w-lg my-0 mx-auto py-3">
                    <div className="w-16 h-12">
                        <a href="/">
                            <div className={"flex"}>
                                <img src={props.color !== undefined ? "/img/logo-" + textColor + ".png" : '/img/logo-black.png'} className={"h-12"} alt="Logo du club de badminton de l'ASFlavigny"/>
                                <div className={"m-2 ml-4 text-3xl font-extralight hidden md:block font-outfit font-light"}>ASFlavigny</div>
                            </div>
                        </a>
                    </div>
                    <nav className="absolute top-0 right-0 hidden md:block w-4/6">
                        <ul className="flex">
                            {navItems}
                        </ul>
                    </nav>
                    <nav className={"absolute top-0 right-0 md:hidden"}>
                        <Mobile ref={popUpRef} items={allItems}/>
                        <div onClick={() => popUpRef.current.seePopup()} className={"space-y-1.5 py-6 cursor-pointer"}>
                            <div style={props.color !== undefined ? {backgroundColor: textColor} : {backgroundColor: 'black'}} className={"rounded-full h-1 w-7"}></div>
                            <div style={props.color !== undefined ? {backgroundColor: textColor} : {backgroundColor: 'black'}} className={"rounded-full h-1 w-7"}></div>
                            <div style={props.color !== undefined ? {backgroundColor: textColor} : {backgroundColor: 'black'}} className={"rounded-full h-1 w-7"}></div>
                        </div>
                    </nav>
                </div>
            </div>
            <div className="border-b border-black"></div>
        </div>
    );
}

export default Navbar;