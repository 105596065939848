import React, {useState} from 'react';
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'
import {useInput} from "../../hooks/input-hook";
import {news_api} from "../../api/news_api";
import Info from "../global/Info";
import {useRef} from "react";

const modules = {
    toolbar: [
        //[{ 'font': [] }],
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike"],
        [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
        ],
        ["link", "image"],
    ],
};

const formats = [
    //'font',
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
];

// Component (here it's a view)
function NewsForm(props) {
    // Updatable variable
    const { value:title, bind:bindTitle } = useInput('');
    // const { value:content, bind:bindContent } = useInput('');
    const [convertedText, setConvertedText] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);

    const infoRef = useRef();

    const resizeImage = (base64Str) => {
        return new Promise((resolve, reject) => {
            var img = new Image();
            img.src = base64Str;
            var canvas = document.createElement('canvas');
            var MAX_WIDTH = 400;
            var MAX_HEIGHT = 400;
    
            img.onload = function() {
                var width = img.width;
                var height = img.height;
    
                if (width < height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width;
                        width = MAX_WIDTH;
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height;
                        height = MAX_HEIGHT;
                    }
                }
                canvas.width = width;
                canvas.height = height;
                var ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, width, height);
                resolve(canvas.toDataURL());
            }
    
            img.onerror = function() {
                reject('Failed to load image');
            }
        });
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const handleSubmit = async (evt) => {
        evt.preventDefault();
        let image = null;
        try {
            image = await toBase64(selectedFile);
        }
        catch {
            infoRef.current.seeInfo("red", "Merci de mettre une image !");
            return;
        }
        let minia = null;
        if (image !== null) {
            minia = await resizeImage(image);
        }
        let url = title.toString().toLowerCase().replace(/ /g, '-');
        url = url.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        url = url.replace(/[^a-z0-9-]/g, "");
        url = url.replace(/-+/g, '-');

        try {
            let result = await news_api.insertNews(title, convertedText, image, minia, url);
            if (result === 204) {
                infoRef.current.seeInfo("green", "News envoyé !");
                setTimeout(() => {
                    props.sended();
                }, 2000);
            } else {
                infoRef.current.seeInfo("red", "Erreur lors de l'envoi de la news !");
            }
        } catch (e) {
            console.log(e);
        }
    }
    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="text-2xl text-center my-5">Ajouter une actualité</div>
                <div className="w-full mb-4">
                    <label>
                        <span className="text-gray-700">Titre</span>
                        <input className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" placeholder="Un titre aguicheur..." {...bindTitle} />
                    </label>
                </div>
                <div className="w-full mb-4">
                    <label>
                        <span className="text-gray-700">Image</span>
                        <input type="file" className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" onChange={(e) => setSelectedFile(e.target.files[0])}/>
                    </label>
                </div>
                <div className="w-full mb-4">
                    <label>
                        <span className="text-gray-700">Contenu</span>
                        <ReactQuill
                            theme='snow'
                            value={convertedText}
                            onChange={setConvertedText}
                            style={{ width: "500px" }}
                            modules={modules}
                            formats={formats}
                        />
                    </label>
                </div>
                <div className="mb-5">
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                        Ajouter l'actualité
                    </button>
                </div>
            </form>
            <div className={"mt-5"}>
                <Info ref={infoRef}/>
            </div>
        </div>
    );
}

export default NewsForm;