import React, {useEffect, useState} from 'react';
import {useInput} from "../../hooks/input-hook";
import {users_api} from "../../api/users_api";

// Component (here it's a view)
function LoginForm() {
    // Updatable variable
    const { value:username, bind:bindUsername, reset:resetFirstName } = useInput('');
    const { value:password, bind:bindPassword, reset:resetLastName } = useInput('');

    const [error, setError] = useState(false);

    const handleSubmit = async (evt) => {
        evt.preventDefault();

        try {
            let result = await users_api.getMyUser(username, password);
            if (!result) {
                console.log("error");
                setError(true);
                return;
            }
            localStorage.username = username;
            localStorage.password = password;
        } catch (e) {
            console.log(e);
            setError(true);
            return;
        }

        resetFirstName();
        resetLastName();

        document.location.href="/";
    }
    const handleSubmitDisconnect = async (evt) => {
        evt.preventDefault();

        try {
            localStorage.removeItem("username");
            localStorage.removeItem(password);
        } catch (e) {
            console.log(e);
        }

        resetFirstName();
        resetLastName();

        document.location.href="/";
    }
    return (
        <div className="sm:shadow-lg sm:rounded p-3">
            {localStorage.getItem('username') === null &&
                <form onSubmit={handleSubmit}>
                    <div className="text-2xl text-center my-5">Connexion</div>
                    <div className="mx-auto my-0 w-44 mb-4">
                        <label>
                            <span className="text-gray-700">Nom d'utilisateur</span>
                            <input
                                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                type="text" placeholder="Nom d'utilisateur" {...bindUsername} />
                        </label>
                    </div>
                    <div className="mx-auto my-0 w-44 mb-5">
                        <label>
                            <span className="text-gray-700">Mot de passe</span>
                            <input
                                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                type="password" placeholder="Mot de passe" {...bindPassword} />
                        </label>
                        {error && <p className="text-red-500 text-xs italic">Nom d'utilisateur ou mot de passe incorrect</p>}
                    </div>
                    <div className="mx-auto my-0 w-44 mb-5">
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-44"
                            type="submit">
                            Connexion
                        </button>
                    </div>
                </form>
            }
            {localStorage.getItem('username') !== null &&
                <form onSubmit={handleSubmitDisconnect}>
                    <div className="text-2xl text-center my-5">Déconnexion</div>
                    <div className="mx-auto my-0 w-44 mb-5">
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-44"
                            type="submit">
                            Déconnexion
                        </button>
                    </div>
                </form>
            }
        </div>
    );
}

export default LoginForm;