import {useParams} from "react-router-dom";
import React, {useEffect, useState} from 'react';
import {tournament_api} from "../api/tournament_api";

function TournamentPaperList () {

    const { tournament } = useParams();

    const [currentTournament, setTournament] = useState({});
    const [players, setPlayers] = useState([]);
    const [matches, setMatches] = useState([]);
    
    function checkTournamentStatus(date, time_start, time_end) {
        // if date is in the future return 1
        // if date is in the past return -1
        // else return 0

        let today = new Date();

        let tournament_date = new Date(date);
        tournament_date.setHours(time_start.split(":")[0], time_start.split(":")[1], time_start.split(":")[2], 0);

        if (tournament_date > today) {
            return 1;
        }
        else {
            // check if current time is between time_start and time_end (time string format: "10:00:00")
            let tournament_time_end = new Date(date);
            tournament_time_end.setHours(time_end.split(":")[0], time_end.split(":")[1], time_end.split(":")[2], 0);
            if (today >= tournament_date && today <= tournament_time_end) {
                return 0;
            }
            else {
                return -1;
            }
        }
    }

    useEffect(() => {
        async function fetchData() {
            const resp = await tournament_api.getTournamentById(tournament);
            const respP = await tournament_api.getPlayersByTournamentId(tournament);
            const respM = await tournament_api.getMatchesByTournamentId(tournament);

            // for each player, get the first match time and the last match time
            for (let i = 0; i < respP.length; i++) {
                let first_match = new Date("9999-12-31T23:59:59Z");
                let last_match = new Date("1970-01-01T00:00:00Z");
                let number_of_matches = 0;
                let player_matches = respM.filter(match => match.player1_id === respP[i].id || match.player2_id === respP[i].id || match.player3_id === respP[i].id || match.player4_id === respP[i].id);
                for (let j = 0; j < player_matches.length; j++) {
                    let match_date = new Date(player_matches[j].date);
                    match_date.setHours(player_matches[j].time_start.split(":")[0], player_matches[j].time_start.split(":")[1], player_matches[j].time_start.split(":")[2], 0);
                    if (match_date < first_match) {
                        first_match = match_date;
                    }
                    if (match_date > last_match) {
                        last_match = match_date;
                    }
                }
                respP[i].first_match = first_match;
                respP[i].last_match = last_match;
                respP[i].number_of_matches = player_matches.length;
            }
        
            setTournament(resp);
            respP.sort((a, b) => (a.lastname > b.lastname) ? 1 : -1);
            setPlayers(respP);

            console.log(respP);

            const status = checkTournamentStatus(resp.date, resp.time_start, resp.time_end);

            if (status === 0) {

            
            }
        }
        fetchData();
    }, []);

    return (
        // generate tournament paper to print
        // split a page in two to print two tournament papers

        // a paper tournament is composed of:
        // - a header with tournament name, date, time, location, and logo
        // - two columns with players
        // - three cases for scores
        <div className="space-y-16">
            { currentTournament.time_start && <div>
                {/* <!-- match paper --> */}
                <div className="border-r border-t border-l border-black">
                    {/* <!-- header --> */}
                    <div className="border-b border-black p-5">
                        <div className="flex justify-between">
                            {/* <!-- logo --> */}
                            <div className="flex flex-col justify-center">
                                <img className="w-16" src="/img/logo-black.png" alt="logo"/>
                            </div>
                            {/* <!-- tournament name --> */}
                            <div className="flex flex-col justify-center">
                                <div className="text-2xl font-bold">{currentTournament.name}</div>
                            </div>
                            {/* <!-- date and time --> */}
                            <div className="flex flex-col justify-center">
                                <div className="text-xl font-bold">{new Date(currentTournament.date).toLocaleDateString('fr-FR')}</div>
                                <div className="text-lg">{currentTournament.time_start.substring(0,5)} - {currentTournament.time_end.substring(0,5)}</div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- players --> */}
                    <div className="">
                        {players.map((player, index) => (
                            <div className="border-b border-black flex justify-between">
                                <div className="py-1 px-2" key={index}>
                                    <div className="flex justify-between">
                                        <div className="flex flex-col justify-center">
                                            <div className="text-xs font-bold">{player.lastname.charAt(0).toUpperCase() + player.lastname.slice(1)} {player.firstname.charAt(0).toUpperCase() + player.firstname.slice(1)}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className="border-l border-black w-7"></div>
                                    <div className="border-l border-black w-2"></div>
                                    <div className="border-l border-black w-14 text-center">
                                        {player.first_match.toTimeString().substring(0,5)}
                                    </div>
                                    <div className="border-l border-black w-14 text-center">
                                        {player.last_match.toTimeString().substring(0,5)}
                                    </div>
                                    <div className="border-l border-black w-7 text-center">
                                        {player.number_of_matches}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div> } 
        </div>
    );
}

export default TournamentPaperList;