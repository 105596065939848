import React, {useEffect, useState, useRef} from 'react';

// Component (here it's a view)
function SlidesHeader(props) {

    const slides = props.slides;

    const [currentSlide, setCurrentSlide] = useState(0); // Gérer l'index de la slide actuelle
    const [resetAnimation, setResetAnimation] = useState(false); // Gérer le reset de l'animation
    const [antiSpam, setAntiSpam] = useState(false); // Gérer le spam du bouton

    const intervalRef = useRef(null);
    const timeout1Ref = useRef(null);
    const timeout2Ref = useRef(null);

    const startXRef = useRef(0); // Position de départ du toucher
    const isSwipingRef = useRef(false); // Indique si un geste de swipe est en cours

    const resetTimer = () => {
        if (intervalRef.current) clearInterval(intervalRef.current);
        if (timeout1Ref.current) clearTimeout(timeout1Ref.current);
        if (timeout2Ref.current) clearTimeout(timeout2Ref.current);

        intervalRef.current = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
            timeout1Ref.current = setTimeout(() => setResetAnimation(true), 5000); // Reset de l'animation
            timeout2Ref.current = setTimeout(() => setResetAnimation(false), 6900); // Reset de l'animation
        }, 7000); // Changement toutes les 7 secondes
    };

    useEffect(() => {
        if (slides.length <= 1) return; // Si une seule slide, on ne fait rien

        resetTimer();

        return () => {
            clearInterval(intervalRef.current);
            clearTimeout(timeout1Ref.current);
            clearTimeout(timeout2Ref.current);
        }; // Nettoyage de l'intervalle
    }, [slides.length]);

    // Gestion des événements de toucher
    const handleTouchStart = (e) => {
        startXRef.current = e.touches[0].clientX;
        isSwipingRef.current = true;
    };

    const handleTouchMove = (e) => {
        if (!isSwipingRef.current) return;
        const deltaX = e.touches[0].clientX - startXRef.current;

        // Si le déplacement est significatif, on empêche le scroll
        if (Math.abs(deltaX) > 10) {
            e.preventDefault();
        }
    };

    const handleTouchEnd = (e) => {
        if (!isSwipingRef.current) return;
        const deltaX = e.changedTouches[0].clientX - startXRef.current;
        isSwipingRef.current = false;

        if (deltaX > 50) {
            // Swipe vers la droite, slide précédente
            if (antiSpam) return;
            setAntiSpam(true);
            setResetAnimation(true);
            setTimeout(() => setResetAnimation(false), 50);
            setTimeout(() => {
                setCurrentSlide((currentSlide - 1 + slides.length) % slides.length)
                resetTimer();
            }, 100);
            setTimeout(() => setAntiSpam(false), 1000);
        } else if (deltaX < -50) {
            // Swipe vers la gauche, slide suivante
            if (antiSpam) return;
            setAntiSpam(true);
            setResetAnimation(true);
            setTimeout(() => setResetAnimation(false), 50);
            setTimeout(() => {
                setCurrentSlide((currentSlide + 1) % slides.length)
                resetTimer();
            }, 100);
            setTimeout(() => setAntiSpam(false), 1000);
        }
    };

    // Gestion des événements de souris pour le glissement sur desktop
    const handleMouseDown = (e) => {
        startXRef.current = e.clientX;
        isSwipingRef.current = true;
    };

    const handleMouseMove = (e) => {
        if (!isSwipingRef.current) return;
        const deltaX = e.clientX - startXRef.current;

        // Si le déplacement est significatif, on empêche le comportement par défaut
        if (Math.abs(deltaX) > 10) {
            e.preventDefault();
        }
    };

    const handleMouseUp = (e) => {
        if (!isSwipingRef.current) return;
        const deltaX = e.clientX - startXRef.current;
        isSwipingRef.current = false;

        if (deltaX > 50) {
            // Swipe vers la droite, slide précédente
            if (antiSpam) return;
            setAntiSpam(true);
            setResetAnimation(true);
            setTimeout(() => setResetAnimation(false), 50);
            setTimeout(() => {
                setCurrentSlide((currentSlide - 1 + slides.length) % slides.length)
                resetTimer();
            }, 100);
            setTimeout(() => setAntiSpam(false), 1000);
        } else if (deltaX < -50) {
            // Swipe vers la gauche, slide suivante
            if (antiSpam) return;
            setAntiSpam(true);
            setResetAnimation(true);
            setTimeout(() => setResetAnimation(false), 50);
            setTimeout(() => {
                setCurrentSlide((currentSlide + 1) % slides.length)
                resetTimer();
            }, 100);
            setTimeout(() => setAntiSpam(false), 1000);
        }
    };

    return (
        <div className={"relative flex h-48 sm:h-96 overflow-hidden w-full"}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={() => { isSwipingRef.current = false; }} // Réinitialiser si la souris quitte l'élément
        >
            {slides.length > 2 && slides.map((slide, index) => (
                <div key={index} className={`absolute w-full h-full ${index === currentSlide ? 'translate-x-0 transition-transform duration-1000' : index === (currentSlide + 1) % slides.length ? `translate-x-full ${resetAnimation ? 'hidden' : 'block'}` : '-translate-x-full transition-transform duration-1000'}`}>
                    {slide.content}
                </div>
            ))}
            {slides.length === 2 && slides.map((slide, index) => (
                <div key={index} className={`absolute w-full h-full ${index === currentSlide ? 'translate-x-0 transition-transform duration-1000' : `${index === 0 ? '-translate-x-full' : 'translate-x-full'} transition-transform duration-1000 ${resetAnimation ? 'hidden' : 'block'}` }`}>
                    {slide.content}
                </div>
            ))}
            {slides.length === 1 && slides.map((slide, index) => (
                <div key={index} className={`absolute w-full h-full`}>
                    {slide.content}
                </div>
            ))}
            {slides.length > 1 && 
                <>
                    <div className="absolute top-1/2 left-0 transform -translate-y-1/2">
                        <button onClick={() => {
                            if (antiSpam) return;
                            setAntiSpam(true);
                            setResetAnimation(true);
                            setTimeout(() => setResetAnimation(false), 50);
                            setTimeout(() => {
                                setCurrentSlide((currentSlide - 1 + slides.length) % slides.length)
                                resetTimer();
                            }, 100);
                            setTimeout(() => setAntiSpam(false), 1000);
                        }} className="text-white font-bold text-lg sm:text-2xl bg-black/10 sm:bg-black/5 hover:bg-black/20 to-green-600/0 bg-opacity-50 p-2 rounded-r-full h-12 sm:h-20">←</button>
                    </div>
                    <div className="absolute top-1/2 right-0 transform -translate-y-1/2">
                        <button onClick={() => {
                            if (antiSpam) return;
                            setAntiSpam(true);
                            setResetAnimation(true);
                            setTimeout(() => setResetAnimation(false), 50);
                            setTimeout(() => {
                                setCurrentSlide((currentSlide + 1) % slides.length)
                                resetTimer();
                            }, 100);
                            setTimeout(() => setAntiSpam(false), 1000);
                        }} className="text-white font-bold text-lg sm:text-2xl bg-black/10 sm:bg-black/5 hover:bg-black/20 to-green-600/0 bg-opacity-50 p-2 rounded-l-full h-12 sm:h-20">→</button>
                    </div>
                </>
            }
        </div>
    )
}

export default SlidesHeader;
