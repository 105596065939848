import React, {useEffect, useState} from 'react';
import ScheduleProp from "../components/schedule/ScheduleProp";
import {schedules_api} from "../api/schedules_api";
import {Helmet} from "react-helmet";

// Component (here it's a view)
function Schedule() {
    // Updatable variable
    const [allschedules, setSchedules] = useState([]);

    const schedules = allschedules.map((schedule) =>
        <ScheduleProp key={schedule.id} schedule = {schedule}/>
    );

    // Function called on load of the component...
    useEffect(() => {
        // Example of fetch API data
        async function fetchData() {
            const resp = await schedules_api.getSchedules();
            setSchedules(resp);
        }
        fetchData();
    }, []);

    // DOM
    return (
        <div className="space-y-10">
            <Helmet>
                <meta property="og:title" content="Horaires d'entraînement | ASFlavigny" />
                <meta property="og:site_name" content="Horaires d'entraînement | ASFlavigny" />
                <meta property="og:description" content="Retrouvez les horaires d'ouverture du badminton club de Flavigny (ASF)." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://asfbad54.fr/horaires" />
                <meta property="og:image" content="https://asfbad54.fr/img/logo-blue.png" />
        
                <meta charSet="utf-8" />
                <title>Horaires d'entraînement | ASFlavigny</title>
                <meta name="description" content="Retrouvez les horaires d'ouverture du badminton club de Flavigny (ASF)." />
                <link rel="canonical" href="/horaires"/>
                <script async src='https://www.googletagmanager.com/gtag/js?id=G-GLK66L6C6D'></script>
                <script type="text/javascript">
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'G-GLK66L6C6D');
                    `}
                </script>
            </Helmet>

            {schedules}
        </div>
    );
}

export default Schedule;
