const { url } = require('./config');

const users_api = {

    // Fetch data...
    async getUsers () {
        const response = await fetch(url + '/users/all');
        const json = await response.json();
        return json;
    },

    async getUserByID(id) {
        const response = await fetch(url + '/users/' + id);
        const json = await response.json();
        return json;
    },

    async getMyUser(username, password) {
        const response = await fetch(url + '/users/my/' + username,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization':"Basic " + window.btoa(username + ':' + password)
                }
            });
        if (response.status === 204) return true;
        return false;
    }

}
export { users_api };