import React from 'react';
import Info from "../global/Info";
import {useRef} from "react";
import {album_api} from "../../api/album_api";

// Component (here it's a view)
function DeleteAlbumForm(props) {
    // Updatable variable
    const infoRef = useRef();

    const handleSubmit = async (evt) => {
        evt.preventDefault();
        try {
            let result = await album_api.deleteAlbum(props.id);
            if (result === 204) {
                infoRef.current.seeInfo("green", "Album supprimé !");
                setTimeout(() => {
                    props.sended();
                }, 2000);
            } else {
                infoRef.current.seeInfo("red", "Erreur lors de la suppression de l'album !");
            }
        } catch (e) {
            console.log(e);
        }
    }
    return (
        <div className={"p-5"}>
            <form onSubmit={handleSubmit}>
                <div className="mb-5">
                    <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                        Supprimer l'album
                    </button>
                </div>
            </form>
            <div className={"mt-5"}>
                <Info ref={infoRef}/>
            </div>
        </div>
    );
}

export default DeleteAlbumForm;