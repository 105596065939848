import React, {useEffect, useState} from 'react';
import {schedules_api} from "../../api/schedules_api";
import ScheduleProp from "../schedule/ScheduleProp";

// Component (here it's a view)
function Schedules() {

    const weekday = ["dimanche","lundi","mardi","mercredi","jeudi","vendredi","samedi"];
    const weekdayOrder = {
        "dimanche": 0,
        "lundi": 1,
        "mardi": 2,
        "mercredi": 3,
        "jeudi": 4,
        "vendredi": 5,
        "samedi": 6
    };

    const d = new Date();
    let day = weekday[d.getDay()];

    const [allOrderedSchedules, setOrderedSchedules] = useState([]);
    const [allSchedules, setSchedules] = useState([]);
    const sortSchedulesByWeekday = (schedules) => {
        return schedules.sort((a, b) => weekdayOrder[a.weekday.toLowerCase()] - weekdayOrder[b.weekday.toLowerCase()]);
    };

    // Function to find current and next schedule
    const findCurrentAndNextSchedules = (schedules) => {
        let todayIndex = -1;
        let nextIndex = -1;

        const nday = d.getDay();
        for (let i = 0; i < schedules.length; i++) {
            const schedule = schedules[i].weekday.toLowerCase(); 
            const nscheduleday = weekdayOrder[schedule]
            if (schedule === day) {
                todayIndex = i;
                nextIndex = (i + 1) % schedules.length;
                break;
            }
            if (todayIndex === -1 && nextIndex === -1 && nday < nscheduleday) {
                nextIndex = i;
            }
        }

        if (nextIndex === -1) {
            return [null, schedules[0]];
        }

        if (todayIndex === -1 && schedules.length > 0) {
            return [null, schedules[nextIndex]];
        }

        return [schedules[todayIndex], schedules[nextIndex]];
    };
    const [currentSchedule, nextSchedule] = findCurrentAndNextSchedules(allOrderedSchedules);

    let today = -1;
    for (let i = 0; i < allOrderedSchedules.length; i++) {
        if (allOrderedSchedules[i].weekday.toLowerCase() === day) {
            today = allOrderedSchedules[i].id;
        }
    }

    const schedules = allSchedules.map((schedule) =>
        <div className={"shadow-2xl relative overflow-hidden h-full w-full"}>
            <p className={"text-white/10 font-outfit text-[300px] leading-[100px] -ml-5 font-extrabold"}>
                {schedule.weekday.toUpperCase().substring(0, 3)}
            </p>
            <p className={"text-white/10 font-outfit text-[300px] leading-[250px] -ml-5 font-extrabold"}>
                {schedule.weekday.toUpperCase().substring(3)}
            </p>
            <div className="absolute top-0 text-white text-7xl sm:text-6xl font-extrabold text-center w-full h-full">
                <div className="content-center h-full">
                    <div className='font-outfit'>
                        {schedule.weekday.toUpperCase()}
                        <div className="ml-3 text-white text-5xl font-medium">
                            {schedule.time_start.substring(0, 5)} - {schedule.time_end.substring(0, 5)}
                        </div>
                        <div className={"flex flex-col items-center"}>
                            <div className="ml-4 flex">
                                <div className="bg-white h-4 w-4 mt-0.5 mr-1">
                                </div>
                                <p className="text-xl leading-none font-light">
                                    {schedule.address}
                                </p>
                            </div>
                        </div>
                        <div className="mt-10 text-white text-3xl font-medium">
                            {schedule.text}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    const lineSchedule = (
        <>
            {currentSchedule && <ScheduleProp key={currentSchedule.id} schedule={currentSchedule} />}
            {!currentSchedule && nextSchedule && <ScheduleProp key={nextSchedule.id} schedule={nextSchedule} />}
        </>
    );

    // Function called on load of the component...
    useEffect(() => {
        // Example of fetch API data
        async function fetchData() {
            const resp = await schedules_api.getSchedules();
            setSchedules([...resp.sort((a, b) => a.id - b.id)]);
            const sortedSchedules = sortSchedulesByWeekday(resp);
            setOrderedSchedules(sortedSchedules);
        }
        fetchData();
    }, []);
    return (
        <div>
            {/*Small screen*/}
            <div className={"lg:hidden"}>
                <h2 className={"text-3xl sm:text-4xl font-bold pb-5"}>
                    <a className='font-outfit' href={"/horaires"}>PROCHAINS HORAIRES</a>
                </h2>
                {lineSchedule}
                <div className={"mt-1 text-right font-outfit font-extralight"}>
                    <a href={"/horaires"}>
                        → Cliquez ici pour voir tous les horaires
                    </a>
                </div>
            </div>
            {/*Large screen*/}
            <div className={"hidden lg:flex relative"}>
                <div className={today === 1 ? "z-20 h-64 bg-gradient-to-r from-green-400 to-green-600 mt-8 w-[30%] relative" : nextSchedule && nextSchedule.id === 1 ? "z-20 h-64 bg-gradient-to-r from-blue-400 to-green-600 mt-8 w-[30%] relative" : "z-20 h-64 bg-gradient-to-r from-blue-400 to-blue-600 mt-8 w-[30%] relative"}>
                    {schedules[0]}
                </div>
                <div  className={today === 2 ? "z-30 h-80 bg-green-400 w-[40%]" : nextSchedule && nextSchedule.id === 2 ? "z-30 h-80 bg-gradient-to-tr from-blue-400 to-green-600 w-[40%]" : "z-30 h-80 bg-blue-400 w-[40%]"}>
                    {schedules[1]}
                </div>
                <div  className={today === 3 ? "z-20 h-64 bg-gradient-to-r from-green-600 to-green-400 mt-8 w-[30%]" : nextSchedule && nextSchedule.id === 3 ? "z-20 h-64 bg-gradient-to-r from-green-600 to-blue-400 mt-8 w-[30%]" : "z-20 h-64 bg-gradient-to-r from-blue-600 to-blue-400 mt-8 w-[30%]"}>
                    {schedules[2]}
                </div>
            </div>
        </div>
    )
}

export default Schedules;