const { url } = require('./config');

const event_api = {
    // Fetch data...
    async getEvents (date, date2) {
        const response = await fetch(url + '/events/' + date + '/' + date2);
        const json = await response.json();
        return json;
    },

    async getEventsFromDate (date) {
        const response = await fetch(url + '/events/' + date);
        const json = await response.json();
        return json;
    },

    async getEventsFromDateWithLimitAndOffset (date, limit, offset) {
        const response = await fetch(url + '/events/' + date + '/' + limit + '/' + offset);
        const json = await response.json();
        return json;
    },

    async getEventsFromDateWithSearch (date, search) {
        const response = await fetch(url + '/events/search/' + date + '?search=' + search);
        const json = await response.json();
        return json;
    },

    async insertEvent(name, type, date, description, link) {
        const response = await fetch(url + '/events',
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization':"Basic " + window.btoa(localStorage.username + ':' + localStorage.password)
                },
                method: "POST",
                body: JSON.stringify({
                    name: name,
                    type: type,
                    date: date,
                    description: description,
                    link: link
                })
            });
        return response.status;
    },

    async deleteEvent(id) {
        const response = await fetch(url + '/events/' + id,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization':"Basic " + window.btoa(localStorage.username + ':' + localStorage.password)
                },
                method: "DELETE"
            });
        return response.status;
    },

    async updateEvent(id, name, type, date, description, link) {
        const response = await fetch(url + '/events/' + id,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization':"Basic " + window.btoa(localStorage.username + ':' + localStorage.password)
                }
                ,method: "PUT",
                body: JSON.stringify({
                    name: name,
                    type: type,
                    date: date,
                    description: description,
                    link: link
                })
            });
        return response.status;
    }
};

export { event_api };